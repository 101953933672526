@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import '~font-awesome/css/font-awesome.css';

// scroll-css --------------------------

@mixin scroll($bgColor, $shadow, $display) {
    &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
        box-shadow: none;
        background-color: $bgColor;
        display: $display;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: transparent;
        box-shadow: $shadow;
    }

    &:hover,
    :focus {
        &::-webkit-scrollbar {
            background-color: rgba(0, 0, 0, 0.12);
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.25);
        }
    }
}

* {
    @include scroll(transparent, none, unset);
}

.show-scroll-bar {
    @include scroll(rgba(0, 0, 0, 0.12), inset 0 0 0 12px rgba(0, 0, 0, 0.25), unset);
}

.hide-scroll-bar {
    @include scroll(transparent, none, none);
}

// font-size-css --------------------------------
.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-29 {
    font-size: 28px;
}

.fs-30 {
    font-size: 30px;
}

.fs-32 {
    font-size: 40px;
}

.fs-36 {
    font-size: 36px;
}

.fs-40 {
    font-size: 32px;
}

.fs-46 {
    font-size: 46px;
}

.fs-46-sm {
    @media screen and (max-width: 768px) {
        font-size: 36px;
    }
}
// font-weight-css -----------------------
.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 800;
}
//  input-field-css ------------------------------

.input_field {
    border: 1px solid #C9CAD9;
    border-radius: 8px;
    padding: 10px 16px;
    margin: 8px 0;
    width: 100%;
    appearance: none;

    &:hover,
    &:focus-visible,
    &:focus,
    &:active {
        box-shadow: none;
        border: 1px solid #C9CAD9;
        outline: unset;
    }

}

.input_field[readonly] {
    opacity: 0.8;
    pointer-events: none;
    background: #cfcfcf8a;

}

// buttons-css ----------------------------
.btn {
    padding: 10px 14px;
    min-width: 100px;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }

    &.btn-primary {
        background: #1d4a16;
        border: 1px solid #1d4a16;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid #1d4a16;
        }

    }

    &.btn-secondary {
        background: #FAD38E;
        border: 1px solid #FAD38E;
        color: #000;
        font-weight: 500;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid #FAD38E;
        }

    }

    &.btn-outline {
        border: 1px solid #1d4a16;
        background: #fff;
        color: #1d4a16;
    }

    &.disable {
        opacity: 0.5;
        cursor: unset;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid #1d4a16;
        }

    }
}

// radius-css ----------------------------
.radius-4 {
    border-radius: 4px;
}

.radius-8 {
    border-radius: 8px;
}

.radius-16 {
    border-radius: 16px;
}

.radius-40 {
    border-radius: 40px;
}


//checkbox-css ----------------------------------------
input[type="checkbox"]+label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 14px;
}

input[type="checkbox"]+label:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 14px;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 5px;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]:checked+label:after {
    content: "";
    width: 11px;
    height: 6px;
    border-bottom-left-radius: 1px;
    position: absolute;
    transform: scale(1) rotate(-45deg);
    left: 5px;
    top: 6px;
    opacity: 1;
}

input[type="checkbox"]+label:before {
    border: 2px solid #C3C8CD;
    background-color: #fff;
}

/* Primary Checkbox */

input[type="checkbox"].primary-checkbox:checked+label:before {
    background-color: #FFE66D;
    border: 2px solid #FFE66D;
}

input[type="checkbox"].primary-checkbox:checked+label:after {
    border-bottom: 2px solid #161616;
    border-left: 2px solid #161616;
}

/* Secondary Checkbox */
input[type="checkbox"].secondary-checkbox:checked+label:before {
    border: 2px solid #1d4a16;
    background: #F7FAF7;
}

input[type="checkbox"].secondary-checkbox:checked+label:after {
    border-bottom: 2px solid #1d4a16;
    border-left: 2px solid #1d4a16;
}


// height width css----------------------------------------
.hw-16 {
    height: 16px;
    width: 16px;
}

.hw-20 {
    height: 20px;
    width: 20px;
}

.hw-24 {
    height: 24px;
    width: 24px;
}

.hw-32 {
    height: 32px;
    width: 32px;
}

.hw-40 {
    height: 40px;
    width: 40px;
    min-width: 40px;
}
.hw-48{
    height: 48px;
    width: 48px;
    min-width: 48px;
    }
.hw-50 {
    height: 50px;
    width: 50px;
}

.hw-56 {
    height: 56px;
    width: 56px;
}
.hw-65 {
    height: 65px;
    width: 65px;
}
.hw-80 {
    height: 80px;
    width: 80px;
}

.hw-95 {
    height: 95px;
    width: 95px;
}
.hw-120 {
    height: 120px;
    width: 120px;
    min-height: 120px;
}
.hw-135 {
    height: 135px;
    width: 135px;
    min-height: 135px;
}
.hw-140 {
    height: 140px;
    width: 140px;
    min-height: 140px;
}
// min width css -------------------------------------------------
.mnw-120{
    min-width: 120px;
}
// max width css -------------------------------------------------
.mxw-950 {
    max-width: 950px;
}
.mxw-850{
    max-width: 850px;
  }
.mxw-800{
    max-width: 800px;
}
.mxw-780 {
    max-width: 780px;
}
.mxw-650 {
    max-width: 650px;
}
.mxw-450 {
    max-width: 450px;
}
.mxw-380 {
    max-width: 380px;
}
.mxw-350 {
    max-width: 350px;
}
.mxw-300 {
    max-width: 300px;
}
.mxw-150 {
    max-width: 150px;
    min-width: 150px;
}
// max heiight css--------------------------------
.mxh-250 {
    max-height: 250px;
}
.mxh-300{
    max-height: 300px;
}
.mnh-300{
    min-height: 300px;
}
.mxh-500{
    max-height: 500px;
}
// min height-css------------------------------------
.mnh-90{
    min-height: 90px;
}
.mnh-120{
    min-height: 120px;
}
/* radio css ------------------------------*/
input[type="radio"]+label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 14px;
}

input[type="radio"]+label:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 14px;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 50%;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 5px;
    opacity: 1;
}

/* Unchecked Radio Button */
input[type="radio"]+label:before {
    border: 2px solid #C3C8CD;
    background-color: #fff;
}

/* Primary Radio Button */
input[type="radio"].primary-radio:checked+label:before {
    background-color: #fff;
    border: 2px solid #FFE66D;
}

input[type="radio"].primary-radio:checked+label:after {
    background-color: #FFE66D;
}

/* Secondary Radio Button */
input[type="radio"].secondary-radio:checked+label:before {
    border: 2px solid #1d4a16;
    background: #F7FAF7;
}

input[type="radio"].secondary-radio:checked+label:after {
    background-color: #1d4a16;
}


// border css --------------------------------------------------------------------
.theme-border-sm {
    border: 1px solid #183813;
}

.theme-border-md {
    border: 2px solid #183813;
}

.theme-border-lg {
    border: 3px solid #183813;
}



// Switch css ----------------------------------------------
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.switch span:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+span {
    background-color: #4CAF50;
}

input:focus+span {
    box-shadow: 0 0 1px #4CAF50;
}

input:checked+span:before {
    transform: translateX(20px);
}

.dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
}

// background css -------------------------------
.blue-bg {
    background-color: #f5f7fa;
}

.theme-green-bg {
    background: linear-gradient(180deg, #0D2306 0%, #183813 100%);
}

.bg-primary {
    background: #1d4a16 !important;
    color: #fff;
}

.bg-lightgreen {
    background: rgb(16, 148, 64);
}

.bg-lightgrey {
    background: #d8d8d8 !important;
}

.lightgreenBG {
    background: #4CAF50 !important;
}
// text-color css-----------------------

.lightgreen-text {
    color: #275B1F
}

.text-grey {
    color: #7E7E7E;
}
.theme-text {
    color: #183813;
}
// other common css---------------------------

.form-control {
    &:hover,
    &:focus-visible,
    &:focus,
    &:active {
        box-shadow: none;
        border: 1px solid #C9CAD9;
        outline: unset;
    }
}

.decoration-none {
    text-decoration: none;
    list-style: none;
}

.position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cursor {
    cursor: pointer;
}

.eye-icon {
    top: 24px;
    right: 12px;
    height: 15px;
    width: 15px;
}

.overlay {
    background: #0008;
    z-index: 3;
    left: 0;
    top: 0;

    .fm-spinner {
        background: #183813;
        height: 60px;
        width: 60px;
        animation: rotate-spinner 1s linear infinite;
        filter: drop-shadow(3px 0px 2px #fff);
        // border-top: 3px solid #fff;
        // #ace7b0

    }
}

@keyframes rotate-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.toaster-wrapper {
    top: 50px;
    right: 20px;
    width: 300px;
    border-radius: 8px;
    z-index: 1061;
    animation: translateX .6s both ease-in-out;

    .close-icon {
        top: 5px;
        right: 5px;
    }

    &.show {
        transform: translateX(0);
    }

    &.success {
        background: #C9F8DC;
        border-top: 3px solid #18B557;

        h1 {
            color: #18B557;
        }
    }

    &.error {
        background: #FDE5E5;
        border-top: 3px solid #EF2727;

        h1 {
            color: #EF2727;
        }
    }

    &.warning {
        background: #FFE7CF;
        border-top: 3px solid #FD952D;

        h1 {
            color: #FD952D;
        }
    }
}

@keyframes translateX {
    from {
        transform: translateX(calc(100% + 20px));
    }

    to {
        transform: translateX(0);
    }
}

.blink_missing {
    animation: blinker 1s linear infinite;
    color: red;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.modal-dialog {
    margin: 0 auto;
}

.table-responsive {
    th {
        color: #888;
        white-space: nowrap;
        font-size: 14px;
        padding: 12px;
        border-bottom: 1px solid lightgrey;
    }

    td {
        max-width: 300px;
        font-size: 14px;
        padding: 12px;
        border-bottom: 1px solid lightgrey;
    }
}



a {
    text-decoration: none;
    color: #158831;
    font-weight: 600;

    &:hover {
        text-decoration: underline;
    }
}


.notes-block {
    position: relative;
    background: #eee;
    border-radius: .4em;
    padding: 10px;
    margin-left: 15px;
}

.notes-block:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-right-color: #eee;
    border-left: 0;
    border-top: 0;
    margin-top: -10px;
    margin-left: -15px;
}

.pagination {
    margin: 0;

    .page-item {
        border: 1px solid #E2E2E2;
        margin: 0 4px;
        border-radius: 4px;
        overflow: hidden;

        .page-link {
            font-size: 13px;
            font-weight: 600;
            color: #000;
            border: none;
        }

        &.active {
            .page-link {
                color: #fff;
                background: rgba(39, 91, 31, 1);
                // border: 1px solid rgba(39, 91, 31, 1);
            }
        }
    }

    .pagination-prev,
    .pagination-next,
    .pagination-first,
    .pagination-last {
        &.disabled {
            opacity: 0.7;
        }
    }
}

app-generate-stripe-element{
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
}

.pointer {
    cursor: pointer;
}

.ngx-select__toggle-buttons {
    margin-left: 20px;
}

.ngx-select__clear {
    min-width: 10px;
}

select:disabled {
    opacity: 1;
    background: #f8f8f9;
}

.sub-primary {
    color: #1d4a16;
}

.ngx-select__toggle {
    padding: .375rem .75rem !important;
}
// .ngx-select__toggle.btn{
//     border-radius:0.25rem!important;
//     .ngx-select__toggle-buttons{
//         padding-left:10px;
//     }

//     .ngx-select__clear.btn{
//         font-size:14px !important;
//     }
// }

// .ngx-select__clear-icon{
//     width:1rem!important;
// }

// .ngx-select__toggle.btn{
//     border-radius:0.25rem!important;
//     .ngx-select__toggle-buttons{
//         padding-left:10px;
//     }

//     .ngx-select__clear.btn{
//         font-size:14px !important;
//     }
// }

// .ngx-select__clear-icon{
//     width:1rem!important;
// }

// .ngx-select_multiple {
//     .ngx-select__selected-plural {
//         span {
//             padding: 3px 0 0 10px;
//             margin: 2px 0 !important;
//             max-width: 280px;
//             text-overflow: ellipsis;
//             white-space: nowrap;
//             overflow: hidden;
//         }
//     }
// }

// .ngx-select__toggle {
//     border: 1px solid $input-border-color !important;
//     border-radius: $input-border-radius;
//     &:hover {
//         background-color: $input-bg !important;
//     }
//     .ngx-select__clear .ngx-select__clear-icon {
//         width: .65rem;
//         color: $body-color !important;
//     }
// }

// .ngx-select_multiple {
//     height: auto !important;
//     padding: 0 !important;
//     .ngx-select__search {
//         height: auto !important;
//         padding: $input-padding-y $input-padding-x !important;
//         margin: 0 !important;
//         &:focus {
//             border-color: $input-focus-border-color !important;
//         }
//     }
// }